
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class="mt-4 mb-5">Conforming Salary for ({{selectedSalaryList.salary_list_month}} / {{selectedSalaryList.salary_list_year}})</h1>
		
			<form @submit.prevent="addSalary" autocomplete="off">
				<!-- {{selectedEmployee}} -->

				<!-- nice design to show this information employee_details: { "employee_id": 8, "employee_name": "niar", "employee_base_salary": 750000, "employee_food_budget": 25000, "employee_transportation_budget": 75000, "employee_phone_budget": 10000, "employee_driver_budget": 0, "employee_government_insurance": 31250, "employee_insurance_rate": 0.05, "supervisor": "Project Manager", "total_salary": 791250, "inssurance_amount": 37500, "hour_rate": 3125, "day_rate": 25000 }-->

				<v-layout row wrap v-if="employee_details.detail.employee_id">

					<v-flex xs12 lg6 xl6 md6 sm4>
						<!-- <h3>employee_id: {{employee_details.detail.employee_id}}</h3> -->
						<h3>
							Name: {{employee_details.detail.employee_name}}
						</h3>
						<h3>
							Base Salary: {{(employee_details.detail.employee_base_salary).toLocaleString()}}
						</h3>
						<h3>
							Food Budget: {{(employee_details.detail.employee_food_budget).toLocaleString()}}
						</h3>
						<h3>
							Transportation Budget: {{(employee_details.detail.employee_transportation_budget).toLocaleString()}}
						</h3>
						<h3>
							Phone Budget: {{(employee_details.detail.employee_phone_budget).toLocaleString()}}
						</h3>
						<h3>
							Driver Budget: {{(employee_details.detail.employee_driver_budget).toLocaleString()}}
						</h3>
						<h3>
							Government Insurance: {{(employee_details.detail.employee_government_insurance).toLocaleString()}}
						</h3>

					</v-flex>
					<v-flex xs12 lg6 xl6 md6 sm4>
						<h3>
							Company Insurance Rate: {{employee_details.detail.employee_insurance_rate}}
						</h3>
						<h3>
							Company Insurance: {{(employee_details.detail.inssurance_amount).toLocaleString()}}
						</h3>
						<h3>
							Total Salary: {{(employee_details.detail.total_salary).toLocaleString()}}
						</h3>
						<p>(Without Overtimes and Iligal Days)</p>
						<h4>
							Working hours in a day: {{employee_details.detail.working_hour_per_day}} Hours
						</h4>
						<h3>
							Hour Budget (base): {{(employee_details.detail.hour_rate).toLocaleString()}}
						</h3>
						<h3>
							Day Budget (base): {{(employee_details.detail.day_rate).toLocaleString()}}
						</h3>
						<h3>
							Day Budget (base + (Food+ Transportation 26 days)): {{(employee_details.detail.day_rate_iligal).toLocaleString()}}
						</h3>

					</v-flex>
				</v-layout>
				<hr class="my-8">
				<v-layout row wrap>
					<v-flex xs12 lg6 xl6 md6 sm4>
						<h3>
							Base Salary : {{(salary.salary_base).toLocaleString()}}
						</h3>

						<h3>
							Gov Inss : {{(salary.salary_gov_inss).toLocaleString()}}
						</h3>
						<h3>
							Fines Total : {{(salary.salary_fines_total).toLocaleString()}}
						</h3>
						<h3>
							Iligal Days Amount : {{(salary.salary_iligal_days_amount).toLocaleString()}}
						</h3>
						<h3>
							Iligal Days Total : {{(salary.salary_iligal_days_total).toLocaleString()}}
						</h3>
						<h3>
							Extra Hours Amount : {{(salary.salary_extra_hours_amount).toLocaleString()}}
						</h3>
						<h3>
							Extra Hours Total : {{(salary.salary_extra_hours_total).toLocaleString()}}
						</h3>
						<h3>
							Total Commissions : {{salary.salary_commission_total}}
						</h3>
						<h3>
							Total Extra Days Amount: {{salary.salary_extra_days_amount}}
						</h3>
						<h3>
							Total Extra Days Total : {{salary.salary_extra_days_total}}
						</h3>
						<h3>
							Inss Amount : {{(salary.salary_inss_amount).toLocaleString()}}
						</h3>
						<h3>
							Total Salary : {{(salary.salary_total).toLocaleString()}}
						</h3>
					</v-flex>

				</v-layout>
				<hr class="my-8">

				<v-layout row wrap class="mt-8">

					<v-flex xs12 lg4 xl4 md3 sm4>
						<v-autocomplete class="mx-1" :items="employees" v-model="salary.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name" @change="fetchEmployeeDetails">
						</v-autocomplete>
					</v-flex>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="salary_list" v-model="salary.salary_list_id" dense filled outlined item-text="salary_list_id" item-value="salary_list_id" :return-object="false" :label="$store.getters.language.data.salary_list.salary_list_id">
						</v-select>
					</v-flex> -->
					<!-- 
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_base" type="text" :label="$store.getters.language.data.salary.salary_base" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_total" type="text" :label="$store.getters.language.data.salary.salary_total" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_gov_inss" type="text" :label="$store.getters.language.data.salary.salary_gov_inss" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_fines_total" type="text" :label="$store.getters.language.data.salary.salary_fines_total" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_iligal_days_amount" type="number" :label="$store.getters.language.data.salary.salary_iligal_days_amount" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_iligal_days_total" type="text" :label="$store.getters.language.data.salary.salary_iligal_days_total" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_extra_hours_amount" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_amount" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_extra_hours_total" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_total" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex> -->

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_total_commission" type="text" :label="$store.getters.language.data.salary.salary_total_commission" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex> -->

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="salary.salary_inss_amount" type="text" :label="$store.getters.language.data.salary.salary_inss_amount" dense class="mx-1" filled outlined required disabled>
						</v-text-field>
					</v-flex> -->

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_insert_date" type="datetime-local" :label="$store.getters.language.data.salary.salary_insert_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.salary.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<!-- <v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" show-select v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="salary_id">
								<template v-slot:[`item.salary_id`]="{ item }">
									<div>
										<v-btn icon :to="'/salary-list/'+item.salary_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectSalary(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteSalaryList">{{$store.getters.language.data.salary.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout> -->
			<!-- {{employee_details.extra_hours}} -->
			<!-- table fro employee_details.extra_hours -->
			<table class="report-table mt-8">
				<tr>
					<!-- <th>extra_hour_id</th> -->
					<th>employee_id</th>
					<th>extra_hour_date</th>
					<th>extra_hour_hours</th>
					<th>extra_hour_note</th>
					<!-- <th>extra_hours_total</th> -->
					<th>extra_hour_insert_date</th>
					<th>Plus amount</th>
				</tr>
				<tr v-for="extra_hour,index in employee_details.extra_hours" :key="index">
					<!-- <td>{{extra_hour.extra_hour_id}}</td> -->
					<td>{{extra_hour.employee_id}}</td>
					<td>{{new Date(extra_hour.extra_hour_date).toLocaleString()}}</td>
					<td>{{extra_hour.extra_hour_hours}}</td>
					<td>{{extra_hour.extra_hour_note}}</td>
					<!-- <td>{{extra_hour.extra_hours_total}}</td> -->
					<td>{{new Date(extra_hour.extra_hour_insert_date).toLocaleString()}}</td>
					<td>{{(extra_hour.total_amount).toLocaleString()}}</td>
				</tr>
			</table>
			<!-- table fro employee_details.iligal_days -->
			<table class="report-table mt-8">
				<thead>
					<tr>
						<th>employee_id</th>
						<th>iligal_day_date</th>
						<th>iligal_day_amount</th>
						<th>iligal_day_note</th>
						<th>iligal_day_insert_date</th>
						<th>Minus amount</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="iligal_day,index in employee_details.iligal_days" :key="index">
						<td>{{iligal_day.employee_id}}</td>
						<td>{{new Date(iligal_day.iligal_day_date).toDateString()}}</td>
						<td>{{iligal_day.iligal_day_amount}}</td>
						<td>{{iligal_day.iligal_day_note}}</td>
						<td>{{new Date(iligal_day.iligal_day_date_insert).toLocaleString()}}</td>
						<td>{{(iligal_day.total_amount).toLocaleString()}}</td>

					</tr>
				</tbody>
			</table>
			<!-- table fro employee_details.fines -->
			<table class="report-table mt-8">
				<thead>
					<tr>
						<th>employee_id</th>
						<th>fine_date</th>
						<th>fine_amount</th>
						<th>fine_note</th>
						<th>fine_insert_date</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="fine,index in employee_details.fines" :key="index">
						<td>{{fine.employee_id}}</td>
						<td>{{new Date(fine.fine_date).toDateString()}}</td>
						<td>{{(fine.fine_amount).toLocaleString()}}</td>
						<td>{{fine.fine_note}}</td>
						<td>{{new Date(fine.fine_date_insert).toLocaleString()}}</td>
					</tr>
				</tbody>
			</table>
			<!-- table fro employee_details.commissions -->
			<table class="report-table mt-8">
				<thead>
					<tr>
						<th>employee_id</th>
						<th>commission_date</th>
						<th>commission_amount</th>
						<th>commission_note</th>
						<th>commission_insert_date</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="commission,index in employee_details.commissions" :key="index">
						<td>{{commission.employee_id}}</td>
						<td>{{new Date(commission.commission_date).toDateString()}}</td>
						<td>{{(commission.commission_amount).toLocaleString()}}</td>
						<td>{{commission.commission_note}}</td>
						<td>{{new Date(commission.commission_insert_date).toLocaleDateString()}}</td>
					</tr>
				</tbody>
			</table>
            <!-- table fro employee_details.extra_days -->
                <table class="report-table mt-8">
                    <thead>
                        <tr>
                            <th>employee_id</th>
                            <th>extra_day_date</th>
                            <th>extra_day_amount</th>
                            <th>extra_day_note</th>
                            <th>extra_day_insert_date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="extra_day,index in employee_details.extra_days" :key="index">
                            <td>{{extra_day.employee_id}}</td>
                            <td>{{new Date(extra_day.extra_day_date).toDateString()}}</td>
                            <td>{{(extra_day.extra_day_amount).toLocaleString()}} Days</td>
                            <td>{{extra_day.extra_day_note}}</td>
                            <td>{{new Date(extra_day.extra_day_insert_date).toLocaleDateString()}}</td>
                        </tr>
                    </tbody>
                </table>

		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.salary.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.salary.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteSalary(selected_salary.salary_id)">
						{{$store.getters.language.data.salary.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import employeesRequest from '../../requests/employees.request.js'
	import requests from './../../requests/salary.request.js'
	import salaryListRequest from '../../requests/salary_list.request.js'
	export default {
		data() {
			return {
				salary: {
					salary_base: 0,
					salary_total: 0,
					salary_gov_inss: 0,
					salary_fines_total: 0,
					salary_iligal_days_amount: 0,
					salary_iligal_days_total: 0,
					salary_extra_hours_amount: 0,
					salary_extra_hours_total: 0,
					salary_commission_total: 0,
					salary_extra_days_amount: 0,
					salary_extra_days_total: 0,
					salary_inss_amount: 0,

				},
				search: '',
				id: '',
				hour_salary: 0,
				day_salary: 0,
				employee_details: {
					detail: {},
					extra_hours: [],
					iligal_days: [],
					fines: [],
					commissions: [],
					extra_days: [],
					extra_hours_total: 0,
					total_iligal_days: 0
				},
				total_extra_days: {},
				total_commissions: 0,
				loading: true,
				loading_btn: false,
				selected_rows: [],
				selectedEmployee: {},
				selectedSalaryList: {},
				employees: [],
				salary_list: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_salary: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.employees.employee_id,
						align: 'start',
						sortable: true,
						value: 'employee_id',
					},
					{
						text: this.$store.getters.language.data.salary_list.salary_list_id,
						align: 'start',
						sortable: true,
						value: 'salary_list_id',
					},
					{
						text: this.$store.getters.language.data.salary.salary_base,
						align: 'start',
						sortable: true,
						value: 'salary_base',
					},
					{
						text: this.$store.getters.language.data.salary.salary_total,
						align: 'start',
						sortable: true,
						value: 'salary_total',
					},
					{
						text: this.$store.getters.language.data.salary.salary_gov_inss,
						align: 'start',
						sortable: true,
						value: 'salary_gov_inss',
					},
					{
						text: this.$store.getters.language.data.salary.salary_fines_total,
						align: 'start',
						sortable: true,
						value: 'salary_fines_total',
					},
					{
						text: this.$store.getters.language.data.salary.salary_iligal_days_amount,
						align: 'start',
						sortable: true,
						value: 'salary_iligal_days_amount',
					},
					{
						text: this.$store.getters.language.data.salary.salary_iligal_days_total,
						align: 'start',
						sortable: true,
						value: 'salary_iligal_days_total',
					},
					{
						text: this.$store.getters.language.data.salary.salary_extra_hours_amount,
						align: 'start',
						sortable: true,
						value: 'salary_extra_hours_amount',
					},
					{
						text: this.$store.getters.language.data.salary.salary_extra_hours_total,
						align: 'start',
						sortable: true,
						value: 'salary_extra_hours_total',
					},
					{
						text: this.$store.getters.language.data.salary.salary_total_commission,
						align: 'start',
						sortable: true,
						value: 'salary_total_commission',
					},
					{
						text: this.$store.getters.language.data.salary.salary_inss_amount,
						align: 'start',
						sortable: true,
						value: 'salary_inss_amount',
					},
					{
						text: this.$store.getters.language.data.salary.salary_insert_date,
						align: 'start',
						sortable: true,
						value: 'salary_insert_date',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'salary_id',
					}
				],
			}
		},
		computed: {

			// employees(){
			//     return this.$store.getters.employees_list
			// },
			// salary_list() {
			// 	return this.$store.getters.salary_list_list
			// },
		},
		mounted() {
			this.id = this.$route.params.id
			this.salary.salary_list_id = this.id
			this.readSalary();
			this.readEmployees();
			this.readsalaryList(this.id);
		},
		methods: {
			fetchEmployeeDetails() {
				this.readEmployeeDetails(this.salary.employee_id);
				// this.selectedEmployee = this.employees.find(employee => employee.employee_id === this.salary.employee_id);
				// this.salary.salary_base = this.selectedEmployee.employee_base_salary;
				// this.salary.salary_total = this.selectedEmployee.employee_base_salary + this.selectedEmployee.employee_phone_budget + this.selectedEmployee.employee_food_budget + this.selectedEmployee.employee_transportation_budget + this.selectedEmployee.employee_driver_budget;
				// this.salary.salary_gov_inss = this.selectedEmployee.employee_government_insurance;
				// this.salary.salary_inss_amount = this.selectedEmployee.employee_insurance_rate * this.salary.salary_base;
				// this.salary.salary_fines_total = this.salary.salary_total - this.salary.salary_gov_inss - this.salary.salary_inss_amount;
				// console.log(this.selectedEmployee);
				// console.log("=================");
				// console.log(this.salary);
				// this.readEmployeeDetails(this.salary.employee_id);
			},
			readEmployeeDetails(employee_id) {
				const query = {
					employee_id: employee_id,
					month: this.selectedSalaryList.salary_list_month,
					year: this.selectedSalaryList.salary_list_year,
					days: this.selectedSalaryList.salary_list_days

				}

				this.loading = true;
				//     async getEmployeeDetails(employee_id, params)  {
				//     return await axios.post(`salary/employee/${employee_id}` , params)
				// },
				requests.getEmployeeDetails(query).then(r => {
					if (r.status == 200) {
						console.log("====================================");
						console.log(r);
						console.log("====================================");
						this.employee_details.detail = r.data.employee
						this.salary.salary_base = this.employee_details.detail.employee_base_salary
						// this.salary.salary_total = this.employee_details.detail.total_salary
						this.employee_details.extra_hours = r.data.employee_extra_hours
						this.employee_details.iligal_days = r.data.employee_iligal_days
						this.employee_details.commissions = r.data.commissions
						this.employee_details.extra_days = r.data.extra_days
						this.employee_details.fines = r.data.employee_fines
						this.employee_details.extra_hours_total = r.data.totla_extra_hours.total_hours
						this.employee_details.total_iligal_days = r.data.total_iligal_days.total_days
						this.employee_details.total_fines = r.data.total_fines.total_fines
						this.salary.salary_inss_amount = r.data.employee.employee_insurance_rate * this.salary.salary_base;
						this.salary.salary_gov_inss = r.data.employee.employee_government_insurance;
						this.salary.salary_extra_hours_total = this.employee_details.extra_hours_total || 0;
						this.salary.salary_iligal_days_total = this.employee_details.total_iligal_days || 0;
						this.salary.salary_fines_total = this.employee_details.total_fines || 0;
						// total_hours_count
						this.salary.salary_extra_hours_amount = r.data.totla_extra_hours.total_hours_count || 0;
						this.salary.salary_iligal_days_amount = r.data.total_iligal_days.total_days_count || 0;
						this.salary.salary_commission_total = r.data.total_commissions.total_commissions || 0;
						this.salary.salary_extra_days_amount = r.data.total_extra_days.total_extra_days || 0;
						this.salary.salary_extra_days_total = r.data.total_extra_days.total_extra_days_amount || 0;

						console.log(r.data.final_salary);
						this.salary.salary_total = r.data.final_salary
						// this.salary.salary_total = parseInt(this.salary.salary_total); // Convert to integer

						this.salary.salary_total = parseFloat(this.salary.salary_total).toString().split('.')[0];
						// get the last 3 digets and put it in a variable 
						let last3digets = this.salary.salary_total.toString().slice(-3);
						console.log(this.salary.salary_total);
						if (last3digets > 850) {
							this.salary.salary_total = parseInt(this.salary.salary_total.toString().slice(0, -3)) + 1 + '000'
						}
						else if (last3digets == 500 || last3digets == 750 || last3digets == 250) {
							this.salary.salary_total = this.salary.salary_total
						}
						else if (last3digets < 150) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '000'
						}
						else if (last3digets < 250 && last3digets > 150) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '250'
						}
						else if (last3digets < 350 && last3digets > 250) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '250'
						}
						else if (last3digets < 500 && last3digets > 350) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '500'
						}

						else if (last3digets > 500 && last3digets < 650) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '500'
						}
						else if (last3digets < 750 && last3digets > 650) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '750'
						}
						else if (last3digets > 750 && last3digets < 850) {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '750'
						}

						else {
							this.salary.salary_total = this.salary.salary_total.toString().slice(0, -3) + '000'
						}
						// change back to integer 
						this.salary.salary_total = parseInt(this.salary.salary_total)
						// if the last 3 digets are greater than 150 
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employee Details',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employee Details',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readsalaryList(salary_list_id) {
				this.loading = true
				salaryListRequest.getOneSalaryList(salary_list_id).then(r => {
					if (r.status == 200) {
						this.selectedSalaryList = r.data.row
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary List',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary List',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},


			readEmployees() {
				this.loading = true
				employeesRequest.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.employees = r.data.rows
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addSalary() {
				this.loading_btn = true
				requests.createSalary(this.salary).then(r => {
					if (r.status == 200) {
						// this.salary = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Salary Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Salary',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteSalary(salary_id) {
				requests.deleteSalary(salary_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.salary_id != salary_id
						})
						this.snackbar = {
							value: true,
							text: 'Salary Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteSalaryList() {
				let ids = this.selected_rows.map(m => m.salary_id)
				requests.deleteSalaryList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.salary_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Salary Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readSalary() {
				this.loading = true
				requests.getAllSalary().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectSalary(i) {
				this.selected_salary = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    